import React, { useEffect } from "react";
import * as styles from "./index.module.scss";
import { Typography } from "@components";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";
import Input from "@components/Input/Input";
import Select from "@components/Select/Select";
import { useHubspotForm, useWindowSize } from "@helpers/Hooks";
import { Submitbutton } from "@components/Button_new/Button";
import GenemodLink from "@components/Link/Link";
import { StaticImage } from "gatsby-plugin-image";
import { BackgroundShapes } from "@components/BackgroundShapes/BackgroundShapes";
import { TeamSizeSelect } from "@components/Select/Select";

const DEMO_SUBMISSION_ROUTE = `https://api.hsforms.com/submissions/v3/integration/submit/19551518/c793b83e-6077-4fad-b6a0-519ea6cd4c9e`;

const conferenceName = "BPI 2024";
const FIND_US = [`${conferenceName} conference`] as const;

export default function BPI2024({ location }: any) {
	const { Option } = Select;
	const { isMobile, isTablet } = useWindowSize();
	const isDesktop = !isMobile && !isTablet;

	useEffect(() => {
		if (document !== undefined) {
			/** to make full height of the page */
			const gatsbyEl = document.getElementById("___gatsby");
			const wrapperEl = document.getElementById("gatsby-focus-wrapper");
			if (!gatsbyEl || !wrapperEl) return;
			gatsbyEl.style.height = "100%";
			wrapperEl.style.height = "100%";
		}
	}, []);

	const { inputProps, handleSubmit, submitStatus, initForm } = useHubspotForm(
		{
			email: location?.state?.email || "",
			firstname: "",
			lastname: "",
			jobtitle: "",
			company: "",
			team_size_range: "",
			how_did_you_find_us_: FIND_US[0],
		},
		DEMO_SUBMISSION_ROUTE
	);

	useEffect(() => {
		if (submitStatus === "SUCCESS") {
			alert("Thanks, we’ll be in touch!");
			initForm();
		}
	}, [submitStatus]);
	return (
		<BackgroundFadeContainer type="blueFade" className={styles.demoWrapper}>
			<BackgroundShapes
				hideLtDesktop={isMobile}
				rightShape={{
					shape: (
						<StaticImage
							loading="eager"
							src={
								"../../../assets/products/network_illustration2.png"
							}
							alt="network illustration"
							height={500}
						/>
					),
					yOffset: isDesktop ? -200 : -250,
					xOffset: isDesktop ? 0 : 50,
				}}
			>
				<div className={styles.demoPage}>
					<div className={styles.demoContainer}>
						<div className={styles.header}>
							<Typography variant="HERO" color="ui-01">
								Sign up to learn more about{" "}
								<span style={{ color: "var(--brand)" }}>
									Genemod
								</span>{" "}
							</Typography>
						</div>
						<div className={styles.body}>
							<Input
								label={"Work email"}
								{...inputProps("email")}
								autoComplete="email"
								fullWidth
								emailValidator
							/>
							<div className={styles.names}>
								<Input
									label={"First name"}
									className={styles.firstName}
									{...inputProps("firstname")}
									autoComplete="given-name"
									fullWidth
									required
								/>
								<Input
									label={"Last name"}
									{...inputProps("lastname")}
									autoComplete="family-name"
									fullWidth
									required
								/>
							</div>
							<Input
								label={"Job title"}
								{...inputProps("jobtitle")}
								fullWidth
								required
							/>
							<Input
								label={"Organization/School name"}
								{...inputProps("company")}
								fullWidth
								required
							/>
							<TeamSizeSelect
								label={"Select team size"}
								{...inputProps("team_size_range")}
								fullWidth
								required
							/>
							<Select
								label={"How did you find us?"}
								{...inputProps("how_did_you_find_us_")}
								fullWidth
								required
								disabled
							>
								{FIND_US.map((opt) => (
									<Option key={opt}>{opt}</Option>
								))}
							</Select>
							<Submitbutton
								submitStatus={"NOT_SUBMITTED"}
								onClick={handleSubmit}
								theme="dark"
							>
								Submit
							</Submitbutton>
							<Typography variant="BODY" color="ui-01">
								By requesting a demo you agree to Genemod’s{" "}
								<GenemodLink
									link="PRIVACY_POLICY"
									className={styles.privacyLink}
								>
									Privacy Policy
								</GenemodLink>
								, and you consent to receive marketing
								communications from Genemod.
							</Typography>
						</div>
					</div>
					<div className={styles.right}>
						<StaticImage
							loading="eager"
							src={"./assets/bpilogo.png"}
							alt={conferenceName}
							style={{
								height: 65,
								width: 264,
							}}
						/>
						<div className={styles.divider} />
						<Typography variant="HEADER4" color="ui-01">
							{`Thank you for visiting us at\n${conferenceName}.`}
						</Typography>
						<Typography
							variant="DESCRIPTION"
							style={{ marginTop: 36 }}
						>
							{`Sign up today for exclusive discounts as a ${conferenceName} attendee!`}
						</Typography>
						<div className={styles.genemodLogo}>
							<StaticImage
								loading="eager"
								src={
									"../../../assets/images/logos/with-white-title.png"
								}
								alt={"white-genemod-logo"}
							/>
						</div>
					</div>
				</div>
			</BackgroundShapes>
		</BackgroundFadeContainer>
	);
}
